<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12">
          <h2 class="color-institucional text-center">
            Elecciones
          </h2>

          <p class="text-center mb-5">
            Para las elecciones en el estado de <b>Baja California Sur</b>
            en el Proceso Local Electoral 2020 - 2021 se eligiran:
          </p>

          <h4 class="text-center color-institucional mb-4">
            Gubernatura
          </h4>

          <section class="text-center mb-5">
            <font-awesome-icon icon="user" class="fa-6x color-terciario mb-3" />

            <p>
              Se elegirá por un periodo de <b>6</b> años al
              próximo <b>Gobernador</b> o próxima <b>Gobernadora</b>
              de este estado.
            </p>
          </section>
          <br />
          <br />
          <br />

          <h4 class="text-center color-institucional mb-4">
            Ayuntamientos
          </h4>

          <section class="text-center mb-5">
            <font-awesome-icon icon="user" class="fa-6x color-terciario mb-3" />

            <p>
              Se elegirá por un periodo de <b>3</b> años a los
              próximos <b>Ayuntamientos</b>, uno por cada uno de los 5 municipio del estado.
            </p>
          </section>
          <br />
          <br />
          <br />

          <h4 class="text-center color-institucional mb-4">
            Diputaciones
          </h4>

          <p class="text-center">
            Son cargos elegidos por la ciudadanía cada 3 años
            e inician sus funciones el 1 de septiembre del año de la elección.
          </p>

          <h5 class="text-center">
            Se eligiran:
          </h5>

          <div class="row text-center">
            <div class="col-12 col-md-6 mb-3">
              <h1>
                16
              </h1>
              <p>
                Diputaciones de Mayoría Relativa
              </p>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <h1>
                5
              </h1>
              <p>
                Diputaciones de Representación Proporcional
              </p>
            </div>
          </div>

          <img src="https://ayuda.ine.mx/2021/informate/assets/img/elecciones/diputaciones_distrito.svg" alt="" class="img-fluid mx-auto d-block mb-3" style="max-width: 350px;">

          <p class="text-center">
            Las características de los principios son:
          </p>
          <br/>
          <br/>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                Por Mayoría Relativa
              </h4>

              <ul>
                <li>
                  Votación directa donde la Candidata o Candidato obtiene la representación
                  de algún Distrito, cuando logra la mayor cantidad de votos en la elección.
                </li>
                <li>
                  Las candidaturas se registran por fórmulas compuestas cada una por una
                  persona propietaria y una suplente, y serán consideradas, fórmulas
                  y candidaturas, separadamente, salvo para efectos de la votación.
                </li>
              </ul>

              <img src="https://ayuda.ine.mx/2021/informate/assets/img/elecciones/diputaciones_1.svg" alt="" class="img-fluid mx-auto d-block">
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <h4>
                Por Representación Proporcional
              </h4>

              <ul>
                <li>
                  Se asignan curules a las candidaturas de los Partidos Políticos
                  de acuerdo al porcentaje de votos que hayan obtenido en la elección.
                </li>
                <li>
                  El número de lugares que le corresponde a cada Partido Político se
                  determina conforme al cociente natural y el resto mayor. El cociente
                  natural es el resultado de dividir la votación nacional emitida entre los
                  puestos a repartir. La asignación de curules se determina dividiendo la
                  votación obtenida por cada partido entre el cociente natural.
                </li>
              </ul>

              <img src="https://ayuda.ine.mx/2021/informate/assets/img/elecciones/diputaciones_2.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <p>
            La Constitución fija un límite a la sobrerrepresentación para que ningún
            Partido Político pueda contar con más de 300 diputaciones por ambos principios,
            y para que ningún partido cuente con un número de diputaciones que exceda en
            ocho puntos el porcentaje de su votación (Ley General de
            Instituciones y Procedimientos Electorales Art. 17).
          </p>

          <p>
            Las candidaturas tanto a Diputaciones Locales como Federales se elegirán
            por los principios de ayoría Relativa y de Representación Proporcional.
          </p>

          <ul class="mb-5">
            <li>
              Se garantizará la paridad, un mínimo de 50% mujeres,
              en las candidaturas a diputaciones federales.
            </li>
            <li>
              El registro por fórmulas, es decir, una persona propietaria
              y una suplente del mismo género, y son consideradas, fórmulas
              y Candidatas o Candidatos, separadamente, salvo para efectos de la votación.
            </li>
          </ul>

          <h4 class="text-center color-institucional">
            ¿Cuándo se da a conocer la integración de la Cámara de Diputados?
          </h4>

          <p>
            La integración de la Cámara se dará a conocer cuándo se determinen los
            Cómputos Distritales y se emitan las constancias de mayoría y validez
            para la asignación de los curules correspondientes de acuerdo con
            los principios de votación.
          </p>

          <div class="row mb-5">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/elecciones/diputaciones-calendario.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/elecciones/diputaciones-constan.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <section class="mb-5">
            <p class="text-center font-weight-bold">
              Referencias:
            </p>

            <p>
              · La Federación, B. (1917). Constitución Política de los Estados
              Unidos Mexicanos. <br />
              · Ley General de Instituciones y Procedimientos
              Electorales, Artículos 17, 414, 415. <br />
              · Constitución Política de los Estados Unidos Mexicanos, Artículo 51 y Artículo
              53 párrafo segundo. <br />
              · Constitución Política de los Estados Unidos Mexicanos, Artículo 51 y
              Artículo 53 párrafo segundo.INE/CG572/2020 Acuerdo del Consejo General del Instituto
              Nacional Electoral por el que se aprueban los criterios aplicables para el
              registro de candidaturas a diputaciones por ambos principios que presenten los
              Partidos Políticos Nacionales y, en su caso, las coaliciones ante los Consejos del
              Instituto, para el Proceso Electoral Federal de 2020-2021.
            </p>
          </section>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul > li {
  margin-bottom: 15px;
  list-style: disc;
  margin-left: 30px;
  padding-left: 15px;
}
</style>
